// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-actions-js": () => import("./../../../src/pages/actions.js" /* webpackChunkName: "component---src-pages-actions-js" */),
  "component---src-pages-allimages-js": () => import("./../../../src/pages/allimages.js" /* webpackChunkName: "component---src-pages-allimages-js" */),
  "component---src-pages-bingooptions-js": () => import("./../../../src/pages/bingooptions.js" /* webpackChunkName: "component---src-pages-bingooptions-js" */),
  "component---src-pages-campaign-clients-js": () => import("./../../../src/pages/campaign_clients.js" /* webpackChunkName: "component---src-pages-campaign-clients-js" */),
  "component---src-pages-campaign-users-js": () => import("./../../../src/pages/campaign_users.js" /* webpackChunkName: "component---src-pages-campaign-users-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-cmsusers-js": () => import("./../../../src/pages/cmsusers.js" /* webpackChunkName: "component---src-pages-cmsusers-js" */),
  "component---src-pages-codes-js": () => import("./../../../src/pages/codes.js" /* webpackChunkName: "component---src-pages-codes-js" */),
  "component---src-pages-copy-campaign-points-js": () => import("./../../../src/pages/copy_campaign_points.js" /* webpackChunkName: "component---src-pages-copy-campaign-points-js" */),
  "component---src-pages-copypoints-js": () => import("./../../../src/pages/copypoints.js" /* webpackChunkName: "component---src-pages-copypoints-js" */),
  "component---src-pages-djs-js": () => import("./../../../src/pages/djs.js" /* webpackChunkName: "component---src-pages-djs-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-export-js": () => import("./../../../src/pages/export.js" /* webpackChunkName: "component---src-pages-export-js" */),
  "component---src-pages-gameoptions-js": () => import("./../../../src/pages/gameoptions.js" /* webpackChunkName: "component---src-pages-gameoptions-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-gamesfolders-js": () => import("./../../../src/pages/gamesfolders.js" /* webpackChunkName: "component---src-pages-gamesfolders-js" */),
  "component---src-pages-imagefolders-js": () => import("./../../../src/pages/imagefolders.js" /* webpackChunkName: "component---src-pages-imagefolders-js" */),
  "component---src-pages-imagegameoptions-js": () => import("./../../../src/pages/imagegameoptions.js" /* webpackChunkName: "component---src-pages-imagegameoptions-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-import-js": () => import("./../../../src/pages/import.js" /* webpackChunkName: "component---src-pages-import-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-langquestions-js": () => import("./../../../src/pages/langquestions.js" /* webpackChunkName: "component---src-pages-langquestions-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-points-js": () => import("./../../../src/pages/points.js" /* webpackChunkName: "component---src-pages-points-js" */),
  "component---src-pages-pointsmap-js": () => import("./../../../src/pages/pointsmap.js" /* webpackChunkName: "component---src-pages-pointsmap-js" */),
  "component---src-pages-push-js": () => import("./../../../src/pages/push.js" /* webpackChunkName: "component---src-pages-push-js" */),
  "component---src-pages-questions-js": () => import("./../../../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */),
  "component---src-pages-sections-js": () => import("./../../../src/pages/sections.js" /* webpackChunkName: "component---src-pages-sections-js" */),
  "component---src-pages-sectionstats-js": () => import("./../../../src/pages/sectionstats.js" /* webpackChunkName: "component---src-pages-sectionstats-js" */),
  "component---src-pages-suggestions-js": () => import("./../../../src/pages/suggestions.js" /* webpackChunkName: "component---src-pages-suggestions-js" */),
  "component---src-pages-textgameoptions-js": () => import("./../../../src/pages/textgameoptions.js" /* webpackChunkName: "component---src-pages-textgameoptions-js" */),
  "component---src-pages-textquestions-js": () => import("./../../../src/pages/textquestions.js" /* webpackChunkName: "component---src-pages-textquestions-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-pages-unplottedpoints-js": () => import("./../../../src/pages/unplottedpoints.js" /* webpackChunkName: "component---src-pages-unplottedpoints-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */)
}

